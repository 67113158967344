// @flow
import { InvitationActionTypes } from './constants';

type InvitationAction = { type: string, payload: {} | string };

// common success
export const invitationApiResponseSuccess = (actionType: string, data: any): InvitationAction => ({
    type: InvitationActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const invitationApiResponseError = (actionType: string, error: string): InvitationAction => ({
    type: InvitationActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});
export const getInvitations = (actionType: string, error: string): InvitationAction => ({
    type: InvitationActionTypes.GET_INVITATIONS,
});

export const createInvitation = (first_name: string, last_name: string, email: string, phone_number: string, birthdate: string, moving_from_address: string, moving_to_address: string,  moving_from_address_id: string, moving_to_address_id: string, moving_date: string, invite_buyer: string, invite_seller: string): InvitationAction => ({
    type: InvitationActionTypes.CREATE_INVITATION,
    payload: { first_name, last_name, email, phone_number, birthdate, moving_from_address, moving_to_address, moving_from_address_id, moving_to_address_id, moving_date, invite_buyer, invite_seller },
});

export const resetInvitation = (): InvitationAction => ({
    type: InvitationActionTypes.RESET,
    payload: {},
});
