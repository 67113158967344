// @flow
import axios from 'axios';
import { getUserFromSession } from '../utils';

function createInvitation(params: any): any {
      return new Promise(function (resolve, reject) {
        setTimeout(function () {
            
            // get parameters from post request
            params.user_id = getUserFromSession().user_id;

            console.log(params);
            
            axios({
                method: "POST",
                url: "https://node.betwn.no/api/invitation/create",
                // url: "http://localhost:3002/api/invitation/create",
                headers: {
                  "Content-Type": 'application/json'
                }, 
                data: params
              }).then(res => {
                if(res.data.status !== 200){
                    reject(res.data.message);
                }else{
                    console.log(res.data);
                    resolve([200, res.data.message]);
                }
              });
        }, 1000);
    });

}

function getInvitations(): any {

  console.log('get invitations');

      return new Promise(function (resolve, reject) {

        setTimeout(function () {
            // get parameters from post request
            axios({
                method: "GET",
                url: "https://node.betwn.no/api/users/invitations/"+getUserFromSession().user_id,
              }).then(res => {
                console.log(res);
                if(res.data.status !== 200){
                    reject(res.data.message);
                }else{
                    resolve([200, res.data.invitations]);
                }
              });
        }, 1000);
    });

}

export { createInvitation, getInvitations };
