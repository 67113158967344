// @flow
import { InvitationActionTypes } from './constants';

import { APICore } from '../../helpers/api/apiCore';

const api = new APICore();

const INIT_STATE = {
    user: api.getLoggedInUser(),
    loading: false,
};

type InvitationAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = { user?: {} | null, loading?: boolean, +value?: boolean };

const Invitation = (state: State = INIT_STATE, action: InvitationAction): any => {
    console.log(action);
    switch (action.type) {
        case InvitationActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case InvitationActionTypes.CREATE_INVITATION: {
                    return {
                        ...state,
                        error: false,
                        createInvite: true,
                        loading: false,
                    };
                }
                case InvitationActionTypes.GET_INVITATIONS: {
                    return {
                        ...state,
                        getInvitesError: false,
                        getInvites: action.payload.data[1],
                        getInvitesLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case InvitationActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case InvitationActionTypes.CREATE_INVITATION: {
                    return {
                        ...state,
                        error: action.payload.error,
                        createInvite: false,
                        loading: false,
                    };
                }
                case InvitationActionTypes.GET_INVITATIONS: {
                    return {
                        ...state,
                        getInvitesError: action.payload.error,
                        getInvites: [],
                        getInvitesLoading: false,
                    };
                }
                default:
                    return { ...state };
            }


        case InvitationActionTypes.CREATE_INVITATION:
            return { ...state, loading: true, createInvite: false, error : false };
        case InvitationActionTypes.GET_INVITATIONS:
            return { ...state, getInvitesLoading: true, getInvites: [], getInvitesError : false };
        case InvitationActionTypes.RESET:
                return {
                    ...state,
                    loading: false,
                    error: false,
                    createInvite: false,
                };
        default:
            return { ...state };
    }
};

export default Invitation;
