// @flow
import { combineReducers } from 'redux';

import Auth from './auth/reducers';
import Invitation from './invitation/reducers';
import Layout from './layout/reducers';

export default (combineReducers({
    Auth,
    Invitation,
    Layout,
}): any);
