import moment from 'moment';

const AUTH_SESSION_KEY = 'between_realestate_user';

export const getUserFromSession = () => {
    const user = window.sessionStorage.getItem(AUTH_SESSION_KEY);
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

export const formatDateForInput = ( date ) => {

    return moment().format(date, 'YYYY-MM-DD');
}

export const capsFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};