// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';

import {
    createInvitation as inviteAPI,
    getInvitations as getInvitesAPI,
} from '../../helpers/';

import { invitationApiResponseSuccess, invitationApiResponseError } from './actions';
import { InvitationActionTypes } from './constants';

/**
 * Login the user
 * @param {*} payload - username and password
 */

function* createInvitation({ payload: { first_name, last_name, email, phone_number, birthdate, moving_from_address, moving_to_address, moving_from_address_id, moving_to_address_id, moving_date, invite_buyer, invite_seller } }) {
    try {
        const response = yield call(inviteAPI, { first_name, last_name, email, phone_number, birthdate, moving_from_address, moving_to_address, moving_from_address_id, moving_to_address_id, moving_date, invite_buyer, invite_seller});

        const user = response[1];

        yield put(invitationApiResponseSuccess(InvitationActionTypes.CREATE_INVITATION, user));
    } catch (error) {
        yield put(invitationApiResponseError(InvitationActionTypes.CREATE_INVITATION, error));
    }
}
function* getInvitations() {

    console.log('saga get invites');

    try {
        const invites = yield call(getInvitesAPI);

        yield put(invitationApiResponseSuccess(InvitationActionTypes.GET_INVITATIONS, invites));
    } catch (error) {
        yield put(invitationApiResponseError(InvitationActionTypes.GET_INVITATIONS, error));
    }
}


export function* watchInvite(): any {
    yield takeEvery(InvitationActionTypes.CREATE_INVITATION, createInvitation);
}
export function* watchGetInvites(): any {
    yield takeEvery(InvitationActionTypes.GET_INVITATIONS, getInvitations);
}
function* invitationSaga(): any {
    yield all([
        fork(watchInvite),
        fork(watchGetInvites),
    ]);
}

export default invitationSaga;
