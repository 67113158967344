// @flow
import { APICore } from './apiCore';
import axios from 'axios';
import md5 from 'md5';

const api = new APICore();

// account
function login(params: any): any {
    // const baseUrl = '/login/';
    // return api.create(`${baseUrl}`, params);
    return new Promise(function (resolve, reject) {
        setTimeout(function () {
            // get parameters from post request
            axios({
                method: "POST",
                url: "https://node.betwn.no/api/login",
                headers: {
                  "Content-Type": "application/json"
                }, 
                data: params
              }).then(res => {
                
                if(res.data.status !== 200){
                    reject(res.data.message);
                }else{
                    let userObj = res.data.user;
                    let [firstName, lastName] = userObj.name.split(' ');
                    let user = [
                        {
                            user_id: userObj.user_id,
                            username: userObj.email,
                            password: md5(userObj.password),
                            firstName: firstName,
                            lastName: lastName,
                            fullname: userObj.name,
                            company: userObj.company,
                            subcompany_id: userObj.subcompany_id,
                            access_id: userObj.access_id,
                            role: 'Admin',
                            token: 'seyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjb2RlcnRoZW1lcyIsImlhdCI6MTU4NzM1NjY0OSwiZXhwIjoxOTAyODg5NDQ5LCJhdWQiOiJjb2RlcnRoZW1lcy5jb20iLCJzdWIiOiJzdXBwb3J0QGNvZGVydGhlbWVzLmNvbSIsImxhc3ROYW1lIjoiVGVzdCIsIkVtYWlsIjoic3VwcG9ydEBjb2RlcnRoZW1lcy5jb20iLCJSb2xlIjoiQWRtaW4iLCJmaXJzdE5hbWUiOiJIeXBlciJ9.P27f7JNBF-vOaJFpkn-upfEh3zSprYfyhTOYhijykdI',
                        },
                    ];

                    resolve([200, user[0]]);
                }
              });
        }, 1000);
    });



}

function logout(): any {
    const baseUrl = '/logout/';
    return api.create(`${baseUrl}`, {});
}

function signup(params: any): any {

      return new Promise(function (resolve, reject) {
        

        setTimeout(function () {
            // get parameters from post request
            axios({
                method: "POST",
                url: "https://node.betwn.no/api/user/create",
                headers: {
                  "Content-Type": "application/json"
                }, 
                data: params
              }).then(res => {
                if(res.data.status !== 200){
                    reject(res.data.message);
                }else{

                    console.log(res.data);

                    let [firstName, lastName] = params.fullname.split(' ');
                    let user = [
                        {
                            user_id: res.data.user_id,
                            username: params.email,
                            password: md5(params.password),
                            firstName: firstName,
                            lastName: lastName,
                            fullname: params.fullname,
                            company: 1,
                            subcompany_id: 1,
                            access_id: 3,
                            role: 'Admin',
                            token: 'seyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjb2RlcnRoZW1lcyIsImlhdCI6MTU4NzM1NjY0OSwiZXhwIjoxOTAyODg5NDQ5LCJhdWQiOiJjb2RlcnRoZW1lcy5jb20iLCJzdWIiOiJzdXBwb3J0QGNvZGVydGhlbWVzLmNvbSIsImxhc3ROYW1lIjoiVGVzdCIsIkVtYWlsIjoic3VwcG9ydEBjb2RlcnRoZW1lcy5jb20iLCJSb2xlIjoiQWRtaW4iLCJmaXJzdE5hbWUiOiJIeXBlciJ9.P27f7JNBF-vOaJFpkn-upfEh3zSprYfyhTOYhijykdI',
                        },
                    ];

                    resolve([200, user[0]]);
                }
              });
        }, 1000);
    });

}

function forgotPassword(params: any): any {
    const baseUrl = '/forget-password/';
    return api.create(`${baseUrl}`, params);
}

function forgotPasswordConfirm(params: any): any {
    const baseUrl = '/password/reset/confirm/';
    return api.create(`${baseUrl}`, params);
}

export { login, logout, signup, forgotPassword, forgotPasswordConfirm };
